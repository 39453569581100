jQuery(document).bind("contextmenu",function(e) {
  e.preventDefault();

});

(function($) {
  "use strict";
  /* ---------------------------------------------------------------------------
 * Global vars
 * --------------------------------------------------------------------------- */
  var rtl 			= $('body').hasClass('rtl');
  var mobile_init_W 	=  1240;

  window.elementorFrontendConfig = {
    "environmentMode" : {
      "edit" : false,
      "wpPreview" : false
    },
    "i18n" : {
      "shareOnFacebook" : "Share on Facebook",
      "shareOnTwitter" : "Share on Twitter",
      "pinIt" : "Pin it",
      "downloadImage" : "Download image"
    },
    "is_rtl" : false,
    "breakpoints" : {
      "xs" : 0,
      "sm" : 480,
      "md" : 768,
      "lg" : 960,
      "xl" : 1440,
      "xxl" : 1600
    },
    "settings" : {
      "page" : [],
      "general" : {
        "elementor_stretched_section_container" : "#Wrapper",
        "elementor_global_image_lightbox" : "yes",
        "elementor_lightbox_enable_counter" : "yes",
        "elementor_lightbox_enable_fullscreen" : "yes",
        "elementor_lightbox_enable_zoom" : "yes",
        "elementor_lightbox_enable_share" : "yes",
        "elementor_lightbox_title_src" : "title",
        "elementor_lightbox_description_src" : "description"
      },
      "editorPreferences" : []
    }
  };
  !function(t){"use strict";if("function"==typeof define&&define.amd)define(["jquery"],t);else if("object"==typeof exports)t(require("jquery"));else{if("undefined"==typeof jQuery)throw"jquery-numerator requires jQuery to be loaded first";t(jQuery)}}(function(t){var e="numerator",i={easing:"swing",duration:500,delimiter:void 0,rounding:0,toValue:void 0,fromValue:void 0,queue:!1,onStart:function(){},onStep:function(){},onProgress:function(){},onComplete:function(){}};function n(n,s){this.element=n,this.settings=t.extend({},i,s),this._defaults=i,this._name=e,this.init()}n.prototype={init:function(){this.parseElement(),this.setValue()},parseElement:function(){var e=t.trim(t(this.element).text());this.settings.fromValue=this.settings.fromValue||this.format(e)},setValue:function(){var e=this;t({value:e.settings.fromValue}).animate({value:e.settings.toValue},{duration:parseInt(e.settings.duration,10),easing:e.settings.easing,start:e.settings.onStart,step:function(i,n){t(e.element).text(e.format(i)),e.settings.onStep(i,n)},progress:e.settings.onProgress,complete:e.settings.onComplete})},format:function(t){return t=parseInt(this.settings.rounding)<1?parseInt(t,10):parseFloat(t).toFixed(parseInt(this.settings.rounding)),this.settings.delimiter?this.delimit(t):t},delimit:function(t){if(t=t.toString(),this.settings.rounding&&parseInt(this.settings.rounding,10)>0){var e=t.substring(t.length-(this.settings.rounding+1),t.length),i=t.substring(0,t.length-(this.settings.rounding+1));return this.addDelimiter(i)+e}return this.addDelimiter(t)},addDelimiter:function(t){return t.toString().replace(/\B(?=(\d{3})+(?!\d))/g,this.settings.delimiter)}},t.fn[e]=function(i){return this.each(function(){t.data(this,"plugin_"+e)&&t.data(this,"plugin_"+e,null),t.data(this,"plugin_"+e,new n(this,i))})}});

  /* ---------------------------------------------------------------------------
   * Zoom Box | Vertical Align
   * --------------------------------------------------------------------------- */
  function zoomBoxVerticalAlign() {
    jQuery('body:not(.style-simple) .zoom_box').each(function() {
      var el = jQuery(this);
      var elH = el.height();
      var desc = el.find('.desc_wrap');
      var descH = desc.height();
      var padding = (elH - descH) / 2;
      desc.css('padding-top', padding + 'px');
    });
  }
  zoomBoxVerticalAlign();

  /* ---------------------------------------------------------------------------
   * Sticky header
   * --------------------------------------------------------------------------- */
  var topBarTop = '61px';
  var mfn_header_height = 0;
// header height
  function mfn_stickyH() {
    if (jQuery('body').hasClass('header-below')) {
      // header below slider
      mfn_header_height = jQuery('.mfn-main-slider').innerHeight() + jQuery('#Header').innerHeight();
    } else {
      // default
      mfn_header_height = jQuery('#Top_bar').innerHeight() + jQuery('#Action_bar').innerHeight();
    }
  }
// init
  function mfn_sticky() {
    if (jQuery('body').hasClass('sticky-header')) {
      var start_y = mfn_header_height;
      var window_y = jQuery(window).scrollTop();
      const $topBar = jQuery('#Top_bar');
      if (window_y > start_y) {
        if (!($topBar.hasClass('is-sticky'))) {
          jQuery('.header-classic .header_placeholder').css('height', $topBar.innerHeight() - jQuery('#Action_bar').innerHeight());
          jQuery('.header-stack   .header_placeholder').css('height', 71);
          jQuery('.header-below   .header_placeholder').css('height', $topBar.innerHeight());
          jQuery('.minimalist-header .header_placeholder').css('height', $topBar.innerHeight());
          $topBar.addClass('is-sticky').css('top', -60).animate({
            'top': jQuery('#wpadminbar').innerHeight()
          }, 300);
        }
      } else {
        if ($topBar.hasClass('is-sticky')) {
          jQuery('.header_placeholder').css('height', 0);
          $topBar.removeClass('is-sticky').css('top', topBarTop);
        }
      }
    }
  }

  /* ---------------------------------------------------------------------------
   * Sidebar height
   * --------------------------------------------------------------------------- */
  function mfn_sidebar() {
    if (jQuery('.with_aside .four.columns').length) {
      var maxH = jQuery('#Content .sections_group').height() - 20
      jQuery('.with_aside .four.columns .widget-area').each(function() {
        jQuery(this).css('min-height', 0);
        if (jQuery(this).height() > maxH) {
          maxH = jQuery(this).height();
        }
      });
      jQuery('.with_aside .four.columns .widget-area').css('min-height', maxH + 'px');
    }
  }

  /* ---------------------------------------------------------------------------
   * Equal Wraps | Height
   * --------------------------------------------------------------------------- */
  function mfn_equalH_wrap() {
    jQuery('.section.equal-height-wrap .section_wrapper').each(function() {
      var maxH = 0;
      jQuery('> .wrap', jQuery(this)).each(function() {
        jQuery(this).css('height', 'auto');
        if (jQuery(this).innerHeight() > maxH) {
          maxH = jQuery(this).innerHeight();
        }
      });
      jQuery('> .wrap', jQuery(this)).css('height', maxH + 'px');
    });
  }

  /* ---------------------------------------------------------------------------
   * Equal Items | Height
   * --------------------------------------------------------------------------- */
  function mfn_equalH() {
    jQuery('.section.equal-height .mcb-wrap-inner').each(function() {
      var maxH = 0;
      jQuery('> .column', jQuery(this)).each(function() {
        jQuery(this).css('height', 'auto');
        if (jQuery(this).height() > maxH) {
          maxH = jQuery(this).height();
        }
      });
      jQuery('> .column', jQuery(this)).css('height', maxH + 'px');
    });
  }

  /* ---------------------------------------------------------------------------
   * Overlay menu
   * --------------------------------------------------------------------------- */
  jQuery('.overlay-menu-toggle').click(function(e) {
    e.preventDefault();
    jQuery(this).toggleClass('focus');
    jQuery('#Overlay').stop(true, true).fadeToggle(500);
    var menuH = jQuery('#Overlay nav').height() / 2;
    jQuery('#Overlay nav').css('margin-top', '-' + menuH + 'px');
  });

  /* ---------------------------------------------------------------------------
   * Sliding Footer | Height
   * --------------------------------------------------------------------------- */
  function mfn_footer() {
    if (jQuery('.footer-fixed #Footer, .footer-sliding #Footer').length) {
      var footerH = jQuery('#Footer').height();
      jQuery('#Content').css('margin-bottom', footerH + 'px');
    }
  }

  /* ---------------------------------------------------------------------------
   * Header width
   * --------------------------------------------------------------------------- */
  function mfn_header() {
    var rightW = jQuery('.top_bar_right').innerWidth();
    var parentW = jQuery('#Top_bar .one').innerWidth() - 10;
    var leftW = parentW - rightW;
    jQuery('.top_bar_left, .menu > li > ul.mfn-megamenu ').width(leftW);
  }

  /* ---------------------------------------------------------------------------
   * Full Screen Section
   * --------------------------------------------------------------------------- */
  function mfn_sectionH() {
    var windowH = jQuery(window).height();
    // FIX | next/prev section
    var offset = 0;
    if( $( '.section.full-screen:not(.hide-desktop)' ).length > 1 ){
      offset = 5;
    }

    $( '.section.full-screen' ).each( function(){

      var section = $( this );
      var wrapper = $( '.section_wrapper', section );

      section
        .css( 'padding', 0 )
        .css( 'min-height', windowH + offset );

      var padding = ( windowH + offset - wrapper.height() ) / 2;

      if( padding < 50 ) padding = 50;

      wrapper
        .css( 'padding-top', padding + 10 )			// 20 = column margin-bottom / 2
        .css( 'padding-bottom', padding - 10 );
    });
  }

  /* ---------------------------------------------------------------------------
   * # Hash smooth navigation
   * --------------------------------------------------------------------------- */
  function hashNav() {
    // # window.location.hash
    var hash = window.location.hash;
    if (hash && jQuery(hash).length) {
      var stickyH = jQuery('.sticky-header #Top_bar').innerHeight();
      var tabsHeaderH = jQuery(hash).siblings('.ui-tabs-nav').innerHeight();
      jQuery('html, body').animate({
        scrollTop: jQuery(hash).offset().top - stickyH - tabsHeaderH
      }, 500);
    }
  }

  /* ---------------------------------------------------------------------------
   * One Page | Scroll Active
   * --------------------------------------------------------------------------- */

  function onePageActive(){
    if( $('body').hasClass('one-page') ){

      var stickyH	= $('.sticky-header #Top_bar').innerHeight();
      var windowT = $(window).scrollTop();
      var start	= windowT + stickyH + $('#wpadminbar').innerHeight() + 1;
      var first 	= false;

      $('div[data-id]').each(function(){

        if( $(this).visible( true ) ){
          if( !first ){
            first = $(this);
          } else if( ( $(this).offset().top < start ) && ( $(this).offset().top > first.offset().top ) ) {
            first = $(this);
          }
        }

        if( first ){
          var newActive = first.attr('data-id');
          var active = '[data-hash="'+ newActive +'"]';

          if( newActive ){
            var menu = $('#menu');
            menu.find('li').removeClass('current-menu-item current-menu-parent current-menu-ancestor current_page_item current_page_parent current_page_ancestor');
            $( active, menu )
              .closest('li').addClass('current-menu-item')
              .closest('.menu > li').addClass('current-menu-item');
          }
        }

      });

    }
  }


  /* ---------------------------------------------------------------------------
   * niceScroll | Padding right fix for short content
   * --------------------------------------------------------------------------- */
  function niceScrollFix() {
    var el = jQuery('body > .nicescroll-rails');
    if (el.length) {
      if (el.is(":visible")) {
        jQuery('body').addClass('nice-scroll');
      } else {
        jQuery('body').removeClass('nice-scroll');
      }
    }
  }
  jQuery(window).ready(function(){
    mfn_equalH_wrap();
    mfn_equalH();
  });

  /* --------------------------------------------------------------------------------------------------------------------------
   * jQuery(document).ready
   * ----------------------------------------------------------------------------------------------------------------------- */
  jQuery(document).ready(function() {
    topBarTop = parseInt(jQuery('#Top_bar').css('top'), 10);
    if (topBarTop < 0) topBarTop = 61;
    topBarTop = topBarTop + 'px';


    /* ---------------------------------------------------------------------------
     * Content sliders
     * --------------------------------------------------------------------------- */
    mfnSliderContent();

    /* ---------------------------------------------------------------------------
     * Retina Logo
     * --------------------------------------------------------------------------- */

    function retinaLogo(){
      if( window.devicePixelRatio > 1 ){

        var el 		= '';
        var src 	= '';
        var height 	= '';

        var parent 	= $( '#Top_bar #logo' );
        var parentH	= parent.data( 'height' );

        var maxH	= {
          sticky : {
            init 			: 35,
            no_padding		: 60,
            overflow 		: 110
          },
          mobile : {
            mini 			: 50,
            mini_no_padding	: 60
          },
          mobile_sticky : {
            init 			: 50,
            no_padding		: 60,
            overflow 		: 80
          }
        };

        $( '#Top_bar #logo img' ).each( function( index ){

          el 		= $( this );
          src 	= el.data( 'retina' );
          height 	= el.height();


          // main -----

          if( el.hasClass( 'logo-main' ) ){

            if( $( 'body' ).hasClass( 'logo-overflow' ) ){

              // do nothing

            } else if( height > parentH ){

              height = parentH;

            }

          }

          // sticky -----

          if( el.hasClass( 'logo-sticky' ) ){

            if( $( 'body' ).hasClass( 'logo-overflow' ) ){

              if( height > maxH.sticky.overflow ){
                height = maxH.sticky.overflow;
              }

            } else if( $( 'body' ).hasClass( 'logo-no-sticky-padding' ) ){

              if( height > maxH.sticky.no_padding ){
                height = maxH.sticky.no_padding;
              }

            } else if( height > maxH.sticky.init ){

              height = maxH.sticky.init;

            }

          }

          // mobile -----

          if( el.hasClass( 'logo-mobile' ) ){

            if( $( 'body' ).hasClass( 'mobile-header-mini' ) ){

              if( parent.data( 'padding' ) > 0 ){

                if( height > maxH.mobile.mini ){
                  height = maxH.mobile.mini;
                }

              } else {

                if( height > maxH.mobile.mini_no_padding ){
                  height = maxH.mobile.mini_no_padding;
                }

              }

            }

          }

          // mobile-sticky -----

          if( el.hasClass( 'logo-mobile-sticky' ) ){

            if( $( 'body' ).hasClass( 'logo-no-sticky-padding' ) ){

              if( height > maxH.mobile_sticky.no_padding ){
                height = maxH.mobile_sticky.no_padding;
              }

            } else if( height > maxH.mobile_sticky.init ){
              height = maxH.mobile_sticky.init;
            }

          }


          // SET

          if( src ){
            el.parent().addClass( 'retina' );
            el.attr( 'src', src ).css( 'max-height', height + 'px' );
          }

        });

      }
    }
    retinaLogo();


    /* ---------------------------------------------------------------------------
     * Responsive menu
     * --------------------------------------------------------------------------- */
    jQuery('.responsive-menu-toggle').click(function(e) {
      e.preventDefault();
      var el = jQuery(this)
      var menu = jQuery('#Top_bar #menu');
      var menuWrap = menu.closest('.menu_wrapper');
      el.toggleClass('active');
      if (el.hasClass('is-sticky') && el.hasClass('active')) {
        var top = 0;
        if (menuWrap.length) top = menuWrap.offset().top;
        jQuery('body,html').animate({
          scrollTop: top
        }, 200);
      }
      menu.stop(true, true).slideToggle(200);
    });

    /* ---------------------------------------------------------------------------
       * Menu | Responsive | Side Slide
       * --------------------------------------------------------------------------- */

    function sideSlide(){

      var slide 				= $( '#Side_slide' );
      var overlay 			= $( '#body_overlay' );
      var ss_mobile_init_W 	= mobile_init_W;
      var pos 				= 'right';


      // constructor ----------

      var constructor = function(){
        if( ! slide.hasClass( 'enabled' ) ){
          $( 'nav#menu' ).detach().appendTo( '#Side_slide .menu_wrapper' );
          slide.addClass( 'enabled' );
        }
      };


      // destructor ----------

      var destructor = function(){
        if( slide.hasClass( 'enabled' ) ){
          close();
          $( 'nav#menu' ).detach().prependTo( '#Top_bar .menu_wrapper' );
          slide.removeClass( 'enabled' );
        }
      };


      // reload ----------

      var reload = function(){

        if( ( $(document).width() < ss_mobile_init_W ) ){
          constructor();
        } else {
          destructor();
        }
      };


      // init ----------

      var init = function(){
        if( slide.hasClass( 'left' ) ){
          pos = 'left';
        }

        if( $( 'body' ).hasClass( 'header-simple' ) ){
          ss_mobile_init_W = 9999;
        }

        reload();
      };


      // reset to default ----------

      var reset = function( time ){

        $( '.lang-active.active', slide ).removeClass('active').children('i').attr('class','icon-down-open-mini');
        $( '.lang-wrapper', slide ).fadeOut(0);

        $( '.icon.search.active', slide ).removeClass('active');
        $( '.search-wrapper', slide ).fadeOut(0);

        $( '.menu_wrapper, .social', slide ).fadeIn( time );

      };


      // menu button ----------

      var button = function(){

        // show
        if( pos == 'left' ){
          slide.animate({ 'left':0 },300);
          $('body').animate({ 'right':-125 },300);
        } else {
          slide.animate({ 'right':0 },300);
          $('body').animate({ 'left':-125 },300);
        }

        overlay.fadeIn(300);

        // reset
        reset(0);

      };


      // close ----------

      var close = function(){

        if( pos == 'left' ){
          slide.animate({ 'left':-250 },300);
          $('body').animate({ 'right':0 },300);
        } else {
          slide.animate({ 'right':-250 },300);
          $('body').animate({ 'left':0 },300);
        }

        overlay.fadeOut(300);
      };


      // search ----------

      $( '.icon.search', slide ).on( 'click', function(e){

        e.preventDefault();

        var el = $(this);

        if( el.hasClass('active') ){

          $( '.search-wrapper', slide ).fadeOut(0);
          $( '.menu_wrapper, .social', slide ).fadeIn(300);

        } else {

          $( '.search-wrapper', slide ).fadeIn(300);
          $( '.menu_wrapper, .social', slide ).fadeOut(0);

          $( '.lang-active.active', slide ).removeClass('active').children('i').attr('class','icon-down-open-mini');
          $( '.lang-wrapper', slide ).fadeOut(0);

        }

        el.toggleClass('active');
      });


      // search form submit ----------

      $( 'a.submit', slide ).on( 'click', function(e){
        e.preventDefault();
        $('#side-form').submit();
      });


      // lang menu ----------

      $( '.lang-active', slide ).on( 'click', function(e){
        e.preventDefault();

        var el = $(this);

        if( el.hasClass('active') ){

          $( '.lang-wrapper', slide ).fadeOut(0);
          $( '.menu_wrapper, .social', slide ).fadeIn(300);
          el.children('i').attr('class','icon-down-open-mini');

        } else {

          $( '.lang-wrapper', slide ).fadeIn(300);
          $( '.menu_wrapper, .social', slide ).fadeOut(0);
          el.children('i').attr('class','icon-up-open-mini');

          $( '.icon.search.active', slide ).removeClass('active');
          $( '.search-wrapper', slide ).fadeOut(0);

        }

        el.toggleClass('active');
      });


      // init, click, debouncedresize ----------

      // init

      init();

      // click | menu button

      $( '.responsive-menu-toggle' ).off( 'click' );

      $( '.responsive-menu-toggle' ).on( 'click', function(e){
        e.preventDefault();
        button();
      });

      // click | close

      overlay.on( 'click', function(e){
        close();
      });

      $( '.close', slide ).on( 'click', function(e){
        e.preventDefault();
        close();
      });

      // click | below search or languages menu

      $( slide ).on( 'click', function(e){
        if( $( e.target ).is( slide ) ){
          reset(300);
        }
      });

      // debouncedresize

      $( window ).on( 'debouncedresize', reload );


    }

    if( $( 'body' ).hasClass( 'mobile-side-slide' ) ){
      sideSlide();
    }





    /* ---------------------------------------------------------------------------
       * Menu | mfnMenu
       * --------------------------------------------------------------------------- */

    function mainMenu(){

      var mm_mobile_init_W = mobile_init_W;

      if( $( 'body' ).hasClass( 'header-simple' ) || $( '#Header_creative.dropdown' ).length ){
        mm_mobile_init_W = 9999;
      }

      $( '#menu > ul.menu' ).mfnMenu({
        addLast		: true,
        arrows		: true,
        mobileInit	: mm_mobile_init_W,
      });

      $( '#secondary-menu > ul.secondary-menu' ).mfnMenu({
        mobileInit	: mm_mobile_init_W,
      });

    }

    mainMenu();

    mfn_stickyH()
    mfn_sticky();

    /* ---------------------------------------------------------------------------
     * Menu | OnePage - remove active
     * Works with .scroll class
     * Since 4.8 replaced with admin option: Page Options / One Page [function: onePageMenu()]
     * --------------------------------------------------------------------------- */
    function onePageScroll() {
      if (!jQuery('body').hasClass('one-page')) {
        var menu = jQuery('#menu');
        if (menu.find('li.scroll').length > 1) {
          menu.find('li.current-menu-item:not(:first)').removeClass('current-menu-item currenet-menu-parent current-menu-ancestor current_page_item current_page_parent current_page_ancestor');
          // menu item click
          menu.find('a').click(function() {
            jQuery(this).closest('li').siblings('li').removeClass('current-menu-item currenet-menu-parent current-menu-ancestor current_page_item current_page_parent current_page_ancestor');
            jQuery(this).closest('li').addClass('current-menu-item');
          });
        }
      }
    }
    onePageScroll();

    /* ---------------------------------------------------------------------------
       * Menu | One Page | Active on Scroll & Click
       * --------------------------------------------------------------------------- */

    function onePageMenu(){
      if( $('body').hasClass('one-page') ){

        var menu = $('#menu');


        // add attr [data-hash] & [data-id] ----------

        $('a[href]', menu).each(function(){

          var url = $(this).attr( 'href' );
          if( url && url.split('#')[1] ){

            // data-hash
            var hash = '#' + url.split('#')[1];
            if( hash && $(hash).length ){				// check if element with specified ID exists
              $(this).attr( 'data-hash', hash );
              $(hash).attr( 'data-id', hash );
            }

            // Visual Composer
            var vcHash = '#' + url.split('#')[1];
            var vcClass = '.vc_row.' + url.split('#')[1];
            if( vcClass && $(vcClass).length ){			// check if element with specified Class exists
              $(this).attr( 'data-hash', vcHash );
              $(vcClass).attr( 'data-id', vcHash );
            }

          }

        });


        // active ----------

        var hash;
        var activeSelector = '.menu > li.current-menu-item, .menu > li.current-menu-parent, .menu > li.current-menu-ancestor, .menu > li.current-page-ancestor, .menu > li.current_page_item, .menu > li.current_page_parent, .menu > li.current_page_ancestor';

        if( $( activeSelector, menu ).length ){

          // remove duplicated
          $( activeSelector, menu )
            .not(':first').removeClass( 'current-menu-item current-menu-parent current-menu-ancestor current-page-ancestor current_page_item current_page_parent current_page_ancestor' );

          // remove if 1st link to section & section is not visible
          hash = $( activeSelector, menu ).find('a[data-hash]').attr( 'data-hash' );

          if( hash ){
            hash = '[data-id="'+ hash +'"]';

            if( $(hash).length && $( hash ).visible( true ) ){
              // do nothing
            } else {
              $( activeSelector, menu ).removeClass( 'current-menu-item current-menu-parent current-menu-ancestor current-page-ancestor current_page_item current_page_parent current_page_ancestor' )
                .closest('.menu > li').removeClass( 'current-menu-item current-menu-parent current-menu-ancestor current-page-ancestor current_page_item current_page_parent current_page_ancestor' );
            }
          } else {
            // do nothing
          }

        } else {

          // add to first if none is active
          var first = $( '.menu > li:first-child', menu );
          var firstA  = first.children('a');

          if( firstA.attr( 'data-hash' ) ){
            hash = firstA.attr( 'data-hash' );
            hash = '[data-id="'+ hash +'"]';

            var wpadminbarH = $('#wpadminbar').innerHeight() * 1;

            if( $(hash).length && ( $(hash).offset().top == wpadminbarH ) ){
              first.addClass( 'current-menu-item' );
            }
          }

        }


        // click ----------

        $('#menu a[data-hash]').click(function(e){
          e.preventDefault(); // only with: body.one-page

          // active

          menu.find('li').removeClass('current-menu-item');
          $(this)
            .closest('li').addClass('current-menu-item')
            .closest('.menu > li').addClass('current-menu-item');

          var hash = $(this).attr('data-hash');
          hash = '[data-id="'+ hash +'"]';

          // mobile - sticky header - close menu

          if( $(window).width() < 768 ){
            $('.responsive-menu-toggle').removeClass('active');
            $('#Top_bar #menu').hide();
          }

          // offset

          var headerFixedAbH = $('.header-fixed.ab-show #Action_bar').innerHeight();
          var tabsHeaderH = $(hash).siblings('.ui-tabs-nav').innerHeight();

          var offset = headerFixedAbH - tabsHeaderH - $('#wpadminbar').innerHeight();

          // sticky height

          var stickyH = fixStickyHeaderH();

          // FIX | Header below | 1st section
          if( $( 'body' ).hasClass( 'header-below' ) && $( '#Content' ).length ){
            if( $( hash ).offset().top < ( $( '#Content' ).offset().top + 60 ) ){
              stickyH = -1;
            }
          }

          // animate scroll

          $( 'html, body' ).animate({
            scrollTop: $( hash ).offset().top - offset - stickyH
          }, 500);

        });

      }
    }
    onePageMenu();

    /* ---------------------------------------------------------------------------
   * FIX | Header | Sticky | Height
   * --------------------------------------------------------------------------- */

    function fixStickyHeaderH(){
      var stickyH = 0;

      // FIX | sticky top bar height
      var topBar = $('.sticky-header #Top_bar');

      if( topBar.hasClass('is-sticky') ){
        stickyH = $('.sticky-header #Top_bar').innerHeight();
      } else {
        topBar.addClass('is-sticky');
        stickyH = $('.sticky-header #Top_bar').innerHeight();
        topBar.removeClass('is-sticky');
      }

      // FIX | responsive
      var responsive = $('.responsive-menu-toggle');
      if( responsive.length ){
        if( responsive.is(":visible") ){
          stickyH = 0;
        }
      }

      return stickyH;
    }


    /* ---------------------------------------------------------------------------
     * Creative Header
     * --------------------------------------------------------------------------- */
    var cHeader = 'body:not(.header-open) #Header_creative';
    var cHeaderEl = jQuery(cHeader);
    var cHeaderCurrnet;

    function creativeHeader() {
      jQuery('.creative-menu-toggle').click(function(e) {
        e.preventDefault();
        cHeaderEl.addClass('active')
        if (jQuery('body').hasClass('header-rtl')) {
          cHeaderEl.animate({
            'right': -1
          }, 500);
        } else {
          cHeaderEl.animate({
            'left': -1
          }, 500);
        }
        cHeaderEl.find('.creative-wrapper').fadeIn(500);
        cHeaderEl.find('.creative-menu-toggle, .creative-social').fadeOut(500);
      });
    }
    creativeHeader();
    jQuery(document).on('mouseenter', cHeader, function() {
      cHeaderCurrnet = 1;
    })
    jQuery(document).on('mouseleave', cHeader, function() {
      cHeaderCurrnet = null;
      setTimeout(function() {
        if (!cHeaderCurrnet) {
          cHeaderEl.removeClass('active');
          if (jQuery('body').hasClass('header-rtl')) {
            cHeaderEl.animate({
              'right': -200
            }, 500);
          } else {
            cHeaderEl.animate({
              'left': -200
            }, 500);
          }
          cHeaderEl.find('.creative-wrapper').fadeOut(500);
          cHeaderEl.find('.creative-menu-toggle, .creative-social').fadeIn(500);
        }
      }, 1000);
    });

    /* ---------------------------------------------------------------------------
     * WP Gallery
     * --------------------------------------------------------------------------- */
    jQuery('.gallery-icon > a').wrap('<div class="image_frame scale-with-grid"><div class="image_wrapper"></div></div>').prepend('<div class="mask"></div>').attr('rel', 'prettyphoto[gallery]').attr('data-rel', 'prettyphoto[gallery]').children('img').css('height', 'auto').css('width', '100%');

    /* ---------------------------------------------------------------------------
     * PrettyPhoto
     * --------------------------------------------------------------------------- */
    if ((typeof(window.mfn_prettyphoto) !== 'undefined' && !window.mfn_prettyphoto.disable)) {
      jQuery('a[rel^="prettyphoto"],a.woocommerce-main-image.zoom, .prettyphoto, a[data-rel^="prettyPhoto[product-gallery]"]').prettyPhoto({
        default_width: window.mfn_prettyphoto.width ? window.mfn_prettyphoto.width : 500,
        default_height: window.mfn_prettyphoto.height ? window.mfn_prettyphoto.height : 344,
        show_title: false,
        deeplinking: false,
        social_tools: false
      });
    }

    /* ---------------------------------------------------------------------------
       * Intro | Scroll v arrow
       * --------------------------------------------------------------------------- */

    jQuery('#Intro .intro-next').click(function(){
      var intro = jQuery(this).closest('#Intro');

      if( intro.next().length ){
        jQuery('html, body').animate({
          scrollTop: intro.next().offset().top - fixStickyHeaderH() - jQuery('#wpadminbar').innerHeight()
        }, 500);
      }
    });

    /* ---------------------------------------------------------------------------
     * Black & White
     * --------------------------------------------------------------------------- */
    jQuery('.greyscale .image_wrapper > a, .greyscale .client_wrapper .gs-wrapper, .greyscale.portfolio-photo a').has('img').BlackAndWhite({
      hoverEffect: true,
      intensity: 1 // opacity: 0, 0.1, ... 1
    });

    /* ---------------------------------------------------------------------------
     * Sliding Top
     * --------------------------------------------------------------------------- */
    jQuery(".sliding-top-control").click(function(e) {
      e.preventDefault();
      jQuery('#Sliding-top .widgets_wrapper').slideToggle();
      jQuery('#Sliding-top').toggleClass('active');
    });

    /* ---------------------------------------------------------------------------
     * Header Search
     * --------------------------------------------------------------------------- */
    jQuery("#search_button, #Top_bar .icon_close").click(function(e) {
      e.preventDefault();
      jQuery('#Top_bar .search_wrapper').fadeToggle();
    });

    /* ---------------------------------------------------------------------------
     * Buttons - mark Buttons with Icon & Label
     * --------------------------------------------------------------------------- */
    jQuery('a.button_js').each(function() {
      var btn = jQuery(this);
      if (btn.find('.button_icon').length && btn.find('.button_label').length) {
        btn.addClass('kill_the_icon');
      }
    });

    /* ---------------------------------------------------------------------------
     * Posts sticky navigation
     * --------------------------------------------------------------------------- */
    jQuery('.fixed-nav').appendTo('body');

    /* ---------------------------------------------------------------------------
     * Feature List
     * --------------------------------------------------------------------------- */
    jQuery('.feature_list ul li:nth-child(4n):not(:last-child)').after('<hr>');

    /* ---------------------------------------------------------------------------
     * IE fixes
     * --------------------------------------------------------------------------- */
    function checkIE() {
      // IE 9
      var ua = window.navigator.userAgent;
      var msie = ua.indexOf("MSIE ");
      if (msie > 0 && parseInt(ua.substring(msie + 5, ua.indexOf(".", msie))) == 9) {
        jQuery("body").addClass("ie");
      }
    }
    checkIE();

    /* ---------------------------------------------------------------------------
     * Parallax Backgrounds
     * --------------------------------------------------------------------------- */
    // var ua = navigator.userAgent,
    //   isMobileWebkit = /WebKit/.test(ua) && /Mobile/.test(ua);
    // if (!isMobileWebkit && jQuery(window).width() >= 768) {
    //   $.stellar({
    //     horizontalScrolling: false,
    //     responsive: true
    //   });
    // }

    /* ---------------------------------------------------------------------------
     * Smooth scroll
     * --------------------------------------------------------------------------- */
    jQuery('li.scroll > a, a.scroll').click(function() {
      var url = jQuery(this).attr('href');
      var hash = '#' + url.split('#')[1];
      var stickyH = jQuery('.sticky-header #Top_bar').innerHeight();
      var tabsHeaderH = jQuery(hash).siblings('.ui-tabs-nav').innerHeight();
      if (hash && jQuery(hash).length) {
        jQuery('html, body').animate({
          scrollTop: jQuery(hash).offset().top - stickyH - tabsHeaderH
        }, 500);
      }
    });

    /* ---------------------------------------------------------------------------
     * Muffin Accordion & FAQ
     * --------------------------------------------------------------------------- */
    jQuery('.mfn-acc').each(function() {
      var el = jQuery(this);
      if (el.hasClass('openAll')) {
        // show all -----------
        el.find('.question').addClass("active").children(".answer").show();
      } else {
        // show one -----------
        var active_tab = el.attr('data-active-tab');
        if (el.hasClass('open1st')) active_tab = 1;
        if (active_tab) {
          el.find('.question').eq(active_tab - 1).addClass("active").children(".answer").show();
        }
      }
    });
    jQuery(".mfn-acc .question > .title").click(function() {
      if (jQuery(this).parent().hasClass("active")) {
        jQuery(this).parent().removeClass("active").children(".answer").slideToggle(200);
      } else {
        if (!jQuery(this).closest('.mfn-acc').hasClass('toggle')) {
          jQuery(this).parents(".mfn-acc").children().each(function() {
            if (jQuery(this).hasClass("active")) {
              jQuery(this).removeClass("active").children(".answer").slideToggle(200);
            }
          });
        }
        jQuery(this).parent().addClass("active");
        jQuery(this).next(".answer").slideToggle(200);
      }
    });

    /* ---------------------------------------------------------------------------
     * Go to top
     * --------------------------------------------------------------------------- */
    jQuery('#back_to_top').click(function() {
      jQuery('body,html').animate({
        scrollTop: 0
      }, 500);
      return false;
    });

    /* ---------------------------------------------------------------------------
     * Section navigation
     * --------------------------------------------------------------------------- */
    jQuery('.section .section-nav').click(function() {
      var el = jQuery(this);
      var section = el.closest('.section');
      if (el.hasClass('prev')) {
        // Previous Section -------------
        if (section.prev().length) {
          jQuery('html, body').animate({
            scrollTop: section.prev().offset().top
          }, 500);
        }
      } else {
        // Next Section -----------------
        if (section.next().length) {
          jQuery('html, body').animate({
            scrollTop: section.next().offset().top
          }, 500);
        }
      }
    });

    /* ---------------------------------------------------------------------------
     * Debouncedresize
     * --------------------------------------------------------------------------- */
    jQuery(window).bind("debouncedresize", function() {
      // carouFredSel wrapper Height set
      mfn_carouFredSel_height();
      // Sidebar Height
      mfn_sidebar();
      // Sliding Footer | Height
      mfn_footer();
      // Header Width
      mfn_header();
      // Full Screen Section
      mfn_sectionH();
      // niceScroll | Padding right fix for short content
      niceScrollFix();
    });

    // carouFredSel wrapper | Height
    mfn_carouFredSel_height();
    // Sidebar | Height
    mfn_sidebar();
    // Sliding Footer | Height
    mfn_footer();
    // Header | Width
    mfn_header();
    // Full Screen Section
    mfn_sectionH();
    // Navigation | Hash
    hashNav();
    // Equal Columns | Height
    //mfn_equalH();
  });

  /* --------------------------------------------------------------------------------------------------------------------------
   * jQuery(window).scroll
   * ----------------------------------------------------------------------------------------------------------------------- */
  jQuery(window).scroll(function() {
    mfn_stickyH();
    mfn_sticky();
    onePageActive();
  });

  /* --------------------------------------------------------------------------------------------------------------------------
   * jQuery(window).load
   * ----------------------------------------------------------------------------------------------------------------------- */
  jQuery(window).ready(function() {

    /* ---------------------------------------------------------------------------
     * Animate Math [counter, quick_fact, etc.]
     * --------------------------------------------------------------------------- */
    // jQuery('.animate-math .number').waypoint({
    //   offset: '100%',
    //   triggerOnce: true,
    //   handler: function() {
    //     var el = jQuery(this);
    //     var duration = Math.floor((Math.random() * 1000) + 1000);
    //     var to = el.attr('data-to');
    //     jQuery({
    //       property: 0
    //     }).animate({
    //       property: to
    //     }, {
    //       duration: duration,
    //       easing: 'linear',
    //       step: function() {
    //         el.text(Math.floor(this.property));
    //       },
    //       complete: function() {
    //         el.text(this.property);
    //       }
    //     });
    //   }
    // });
    // carouFredSel wrapper | Height
    mfn_carouFredSel_height();
    // Sidebar | Height
    mfn_sidebar();
    // Sliding Footer | Height
    mfn_footer();
    // Header | Width
    mfn_header();
    // Full Screen Section
    mfn_sectionH();
    // Navigation | Hash
    hashNav();
    // niceScroll | Padding right fix for short content
    niceScrollFix();
  });

  /* ---------------------------------------------------------------------------
   * Sliders configuration
   * --------------------------------------------------------------------------- */
// carouFredSel wrapper Height set -------------------------------------------
  function mfn_carouFredSel_height() {
    jQuery('.caroufredsel_wrapper > ul').each(function() {
      var el = jQuery(this);
      var maxH = 0;
      el.children('li').each(function() {
        if (jQuery(this).innerHeight() > maxH) {
          maxH = jQuery(this).innerHeight();
        }
      });
      //			console.log(maxH);
      el.closest('.caroufredsel_wrapper').height(maxH);
    });
  }

// --- Portfolio -------------------------------------------------------------

// Slick Slider | Auto responsive -----------------------------------------

  function slickAutoResponsive( slider, max, size ){

    if( ! max ) max = 5;
    if( ! size ) size = 380;

    var width = slider.width();
    var count = Math.ceil( width / size );

    if( count < 1 ) count = 1;
    if( count > max ) count = max;

    return count;
  }



// --- Slider ----------------------------------------------------------------

  function mfnSliderContent(){
    $('.content_slider_ul').each(function(){
      if($(this).hasClass("carouFredSel")){
        let style = $(this).closest('.content_slider').hasClass('carousel') ? { min:1, max:6} : 1;

        // Init carouFredSel
        $( this ).carouFredSel({
          circular	: true,
          responsive	: true,
          items		: {
            width	: 380,
            visible	: style
          },
          scroll		: {
            duration	: 500,
            easing		: 'swing'
          },
          prev        : {
            button		: function(){
              return $(this).closest('.content_slider').find('.slider_prev');
            }
          },
          next        : {
            button		: function(){
              return $(this).closest('.content_slider').find('.slider_next');
            }
          },
          pagination	: {
            container	: function(){
              return $(this).closest('.content_slider').find('.slider_pagination');
            }
          },
          auto		: {
            play			: !!window.mfn_sliders.slider,
            timeoutDuration	: window.mfn_sliders.slider ? window.mfn_sliders.slider : 2500,
          },
          swipe		: {
            onTouch		: true,
            onMouse		: true,
            onBefore	: function(){
              $(this).find('a').addClass('disable');
              $(this).find('li').trigger('mouseleave');
            },
            onAfter		: function(){
              $(this).find('a').removeClass('disable');
            }
          }
        });

        // Disable accidental clicks while swiping
        $(this).on('click', 'a.disable', function() {
          return false;
        });
      }else{


        var pager = function( el, i ){
          return '<a>'+ i +'</a>';
        };


        var slider 		= $(this);
        var count 		= 1;
        var centerMode	= false;

        if( slider.closest( '.content_slider' ).hasClass( 'carousel' ) ){
          count = slickAutoResponsive( slider );

          $(window).bind( 'debouncedresize', function(){
            slider.slick( 'slickSetOption', 'slidesToShow', slickAutoResponsive( slider ), false );
            slider.slick( 'slickSetOption', 'slidesToScroll', slickAutoResponsive( slider ), true );
          });
        }

        if( slider.closest( '.content_slider' ).hasClass( 'center' ) ){
          centerMode = true;
        }

        if(slider.siblings( '.slider_prev' ).length){
          slider.slick({
            cssEase			: 'cubic-bezier(.4,0,.2,1)',
            dots			: true,
            infinite		: true,
            touchThreshold	: 10,
            speed			: 300,

            centerMode		: centerMode,
            centerPadding	: '20%',

            prevArrow		:  slider.siblings( '.slider_prev' ),
            nextArrow		:  slider.siblings( '.slider_next' ),

            adaptiveHeight	: true,
            appendDots		: slider.siblings( '.slider_pager' ),
            customPaging 	: pager,

            rtl				: !!rtl,
            autoplay		: !!window.mfn_sliders.slider,
            autoplaySpeed	: window.mfn_sliders.slider ? window.mfn_sliders.slider : 5000,

            slidesToShow	: count,
            slidesToScroll	: count
          });


        }	else{
          slider.slick({
            cssEase			: 'cubic-bezier(.4,0,.2,1)',
            dots			: true,
            infinite		: true,
            touchThreshold	: 10,
            speed			: 300,

            centerMode		: centerMode,
            centerPadding	: '20%',

            prevArrow		: '<a class="button button_js slider_prev" href="#"><span class="button_icon"><i class="icon-left-open-big"></i></span></a>',
            nextArrow		: '<a class="button button_js slider_next" href="#"><span class="button_icon"><i class="icon-right-open-big"></i></span></a>',


            adaptiveHeight	: true,
            appendDots		: slider.siblings( '.slider_pager' ),
            customPaging 	: pager,

            rtl				: !!rtl,
            autoplay		: !!window.mfn_sliders.slider,
            autoplaySpeed	: window.mfn_sliders.slider ? window.mfn_sliders.slider : 5000,

            slidesToShow	: count,
            slidesToScroll	: count
          });
        }
      }
    });
  }
  window.mfn_nicescroll = 25;
  window.mfn_prettyphoto = {
    disable: 0,
    width: 0,
    height: 0
  };
  window.mfn_sliders = {
    blog: 0,
    clients: 0,
    offer: 10000,
    portfolio: 0,
    shop: 0,
    slider: 6000,
    testimonials: 7000
  };
  jQuery(document).ready(function($) {
    jQuery('#configurator .control').click(function(e) {
      e.preventDefault();
      const $configurator = jQuery("#configurator");
      if ($configurator.hasClass('active')) {
        $configurator.removeClass('active').animate({
          'right': -272
        }, 500);
      } else {
        $configurator.addClass('active').animate({
          'right': -1
        }, 500);
      }
    });

    /* ---------------------------------------------------------------------------
     * niceScroll
     * --------------------------------------------------------------------------- */
    jQuery(".demos-slider").niceScroll({
      autohidemode: false,
      cursorborder: 0,
      cursorborderradius: 5,
      cursorcolor: '#222222',
      cursorwidth: 0,
      horizrailenabled: false,
      mousescrollstep: 40,
      scrollspeed: 60
    });

    /* ---------------------------------------------------------------------------
     * Parallax Backgrounds
     * --------------------------------------------------------------------------- */
    if (typeof stellar !== 'undefined' && $.isFunction(stellar)) {

      var ua = navigator.userAgent,
        isMobileWebkit = /WebKit/.test(ua) && /Mobile/.test(ua);
      if (!isMobileWebkit && jQuery(window).width() >= 768) {
        if (window.mfn_parallax == 'stellar') {
          // Stellar
          jQuery.stellar({
            horizontalScrolling: false,
            responsive: true
          });
        } else {
          // Enllax
          jQuery(window).enllax();
        }
      } else {
        jQuery('.section[data-enllax-ratio], .section[data-stellar-ratio]').css('background-attachment', 'scroll');
        jQuery('div[data-enllax-ratio], div[data-stellar-ratio]').css( 'background-attachment' , 'scroll' );
      }
    }
  });
})(jQuery);
