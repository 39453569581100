import "../css/bootstrap.min.css";
import "../css/pretty.css";
import "../css/structure.css";
import "../css/estate3.css";
// import "../css/styles.pure.css";

import "../js/jquery-global";
import $ from "jquery";
import "../js/mfn.menu";
import "../js/jquery.plugins";
import "../js/scripts";
